import "core-js/modules/es6.array.map.js";
export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var items = computed(function () {
      var _props$impact, _props$impact$geograp;
      return (_props$impact = props.impact) === null || _props$impact === void 0 ? void 0 : (_props$impact$geograp = _props$impact.geographies) === null || _props$impact$geograp === void 0 ? void 0 : _props$impact$geograp.map(function (e) {
        return e.label;
      });
    });
    return {
      items: items
    };
  },
  computed: {}
});